var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @ts-nocheck
import Tinker from '../lib/tinker/index';
import { upSavePointer } from '../lib/utils';
import store from '../store';
var rootURL = 'https://ssl.newbeestudio.com';
var thirdURL = 'https://api.money.126.net';
/**
 *  查询是否关注公众号
 *  @param params
 */
export var querySubscribe = function () { return new Tinker({
    url: '/api/v2/user/querySubscribe',
    header: { method: 'GET' },
    dataType: 'json',
}); };
// 活动1
export var fetchActiveTime = function () { return new Tinker({
    url: "/api/v2/config/get?key=OPERATE_ONE_YEAR_STAGE",
    header: { method: 'GET' },
}); };
// 活动2
export var fetchActiveStatus = function () { return new Tinker({
    url: "/api/v2/operate/oneYear/status",
    header: { method: 'GET' },
}); };
// 活动3
export var fetchActiveVip = function () { return new Tinker({
    url: "/api/v2/operate/oneYear/receive",
    header: { method: 'GET' },
}); };
//活动3000VIp
export var fetchReciveVip = function (params) { return new Tinker({
    url: "/api/v2/user/receiveVip",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var fetchStockInfo = function (params) { return new Tinker({
    url: "/api/v2/capital/info",
    data: params,
    header: { method: 'GET' }
}); };
export var fetchStockInfov2 = function (params) { return new Tinker({
    url: "/api/v2/module/stock",
    data: params,
    header: { method: 'GET' }
}); };
export var new_fetchhbStockInfo = function (params) { return new Tinker({
    url: "/api/v2/open/stockInfo?source=" + params.source + "&token=" + params.token,
    // data: params,
    header: { method: 'GET' }
}); };
export var fetchhbStockInfo = function (params) { return new Tinker({
    url: "/api/v2/open/stockInfo",
    data: params,
    header: { method: 'GET' }
}); };
export var paramSencrypt = function (params) { return new Tinker({
    url: "/api/v2/open/token",
    data: params,
    header: { method: 'GET' }
}); };
export var fetchListOptional = function (params) { return new Tinker({
    url: "/api/v2/opt/listOptV4",
    data: params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getListOptV3 = function (params) { return new Tinker({
    url: "/api/v2/opt/listOptV3",
    data: params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getListOptV4 = function (params) { return new Tinker({
    url: "/api/v2/opt/listOptV4",
    data: params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getListOptItems = function (params, type, subColumn) { return new Tinker({
    url: "/api/v2/opt/listOptionalV4Items?column=" + type + (subColumn ? "&subColumn=" + subColumn : ''),
    data: params,
    header: { method: 'POST' },
    dataType: 'json',
}); };
export var getListOptV3Items = function (params) { return new Tinker({
    url: "/api/v2/opt/listOptionalV3Items",
    data: params,
    header: { method: 'POST' },
    dataType: 'json',
}); };
var header = document.head || document.getElementsByTagName('head')[0];
export var fetchStock = function (stocks) {
    var script = document.createElement('script');
    script.src = thirdURL + "/data/feed/" + stocks.join(',') + "?callback=fetchStock";
    header.appendChild(script);
};
export var fetchStocks = function (stocks) {
    var script = document.createElement('script');
    script.src = thirdURL + "/data/feed/" + stocks.join(',') + "?callback=fetchStocks";
    header.appendChild(script);
};
// 日报
export var fetchDaliyData = function (date) { return new Tinker({
    url: "/api/v2/weekly/daily?date=" + date,
    header: { method: 'GET' },
    dataType: 'json',
}); };
// // 活动1
// export const fetchActiveTime = () =>new Tinker({
//     url:`/api/v2/config/get?key=OPERATE_ONE_YEAR_STAGE`,
//     header:{ method:'GET' },
// })
// // 活动2
// export const fetchActiveStatus = () =>new Tinker({
//     url:`/api/v2/operate/oneYear/status`,
//     header:{ method:'GET' },
// })
// // 活动3
// export const fetchActiveVip = () =>new Tinker({
//     url:`/api/v2/operate/oneYear/receive`,
//     header:{ method:'GET' },
// })
/**
 * 股票搜索
 * @param {*} params
 */
export var fetchSearch = function (params) { return new Tinker({
    url: "/api/v2/capital/searchWithIndex",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 搜索股票 指数 基金
 * @param {*} params
 */
export var fetchAllSearch = function (params) { return new Tinker({
    url: "/api/v2/capital/searchV4",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 股票搜索 不含指数
 * @param {*} params
 */
export var fetchSearchWithoutIndex = function (params) { return new Tinker({
    url: "/api/v2/capital/search",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 港股搜索 不含指数
 * @param {*} params
 */
export var fetchSearchWithoutHk = function (params) { return new Tinker({
    url: "/api/v2/capital/hk/search",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 大V用户明细
 * @param {*} params
 */
// export const fetchInviteUsers = (params?: { id: number }) => new Tinker({
//     url: `/api/v2/V/users`,
//     data: params,
//     header: { method: 'GET' }
// });
export var fetchInviteUsers = function (params) { return new Tinker({
    url: "/api/v2/V/users",
    data: params,
    header: { method: 'GET' }
}); };
// // 非交易日
// export const getUnOpenDates = (params: TinkerParams) => new Tinker({
//     url: `/api/v2/capital/unOPenDatas`,
//     data: params,
// });
// // 推荐股票列表
// export const getStockListFetch = (params: TinkerParams) => new Tinker({
//     url: `${rootURL}/api/v2/capital/recommend`,
//     data: params,
// });
/**
 * 微信登录
 * @param {*} params
 */
export var fetchWXLogin = function (params) { return new Tinker({
    url: "/api/v2/wx/rest/outh",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 加自选
 * @param {*} params
 */
export var fetchAddOptional = function (params) { return new Tinker({
    url: "/api/v2/capital/addOptional",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 加自选 并添加 推送自选股
 * @param {*} params
 */
export var AddOptional = function (params) { return new Tinker({
    url: "/api/v2/opt/addOpt",
    header: { method: 'GET' },
    data: params,
}); };
export var fetchAddOptionalAndPushSeting = function (params) { return new Tinker({
    url: "/api/v2/capital/addOptional",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 删自选
 * @param {*} params
 */
export var fetchDelOptional = function (params) { return new Tinker({
    url: "/api/v2/capital/delOptional",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 微信验证配置
 * @param {*} params
 */
export var getWXconfig = function (params) { return new Tinker({
    url: "/api/v2/wx/share/sign",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 自选股推荐股票
 * @param {*} params
 */
export var firstRecommend = function (params) { return new Tinker({
    url: "/api/v2/capital/firstRecommend",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 自选股推荐基金
 * @param {*} params
 */
export var firstRecommendOutFund = function (params) { return new Tinker({
    url: "/api/v2/capital/firstRecommendOutFund",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 自选查询成立时间
 * @param {*} params
 */
export var queryBeginDate = function (params) { return new Tinker({
    url: "/api/v2/opt/queryBeginDate",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 股票对比
 * @param {*} params  股票码集合，多个逗号隔开 例如000001.SZ,000002.SZ
 */
export var compareStocks = function (params) { return new Tinker({
    // url: `/api/v2/capital/compare`,
    url: '/api/v2/capital/compareNew',
    header: { method: 'GET' },
    data: params,
}); };
export var fetchCodeData = function () { return new Tinker({
    url: '/api/v2/index/allCodes',
    header: { method: 'POST' },
}); };
// export const fetchStockList = (params, callback) => {
//     wx.request({
//         url: `${thirdURL}/data/feed/${params.join(',')}`,
//         dataType: 'text',
//         success(res) {
//             if (res.statusCode === 200) {
//                 try {
//                     res.data = res.data.replace('_ntes_quote_callback', '');
//                     res.data = res.data.replace(';', '');
//                     res.data = res.data.replace('(', '');
//                     res.data = res.data.replace(')', '');
//                     res.data = JSON.parse(res.data);
//                     callback && callback(res.data);
//                 } catch (e) {
//                     console.error(e);
//                 }
//             }
//         },
//         fail(res) {
//             console.error(res);
//         }
//     })
// }
// export const fetchStock = (code, callback) => {
//     wx.request({
//         url: `${thirdURL}/data/feed/${code}`,
//         dataType: 'text',
//         success(res) {
//             if (res.statusCode === 200) {
//                 try {
//                     res.data = res.data.replace('_ntes_quote_callback', '');
//                     res.data = res.data.replace(';', '');
//                     res.data = res.data.replace('(', '');
//                     res.data = res.data.replace(')', '');
//                     res.data = JSON.parse(res.data);
//                     callback && callback(res.data);
//                 } catch (e) {
//                     console.error(e);
//                 }
//             }
//         },
//         fail(res) {
//             console.error(res);
//         }
//     })
// }
// new Tinker({
//     url: `${thirdURL}/data/feed/${params.join(',')}`,
//     dataType: 'text',
// });
// /**
//  * 生成关联公众号二维码
//  * @param {*} params
//  */
// export const getParamQrcode = () => new Tinker({
//     url: `${rootURL}/api/v2/wx/paramQrcode`,
// });
/**
 * 批量删除自选股
 * @param {*} params
 */
export var batchDelOptional = function (params) { return new Tinker({
    url: "/api/v2/capital/batchDelOptional",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 排序自选股
 * @param {*} params
 */
export var sortOptional = function (params, groupId) { return new Tinker({
    url: "/api/v2/opt/sortOpt?groupId=" + groupId,
    header: { method: 'POST' },
    data: params,
}); };
// /**
//  * 是否实时数据
//  */
// export const isLive = (params) => new Tinker({
//     url: `${rootURL}/api/v2/config/get`,
//     data: params,
// });
export var fetchIndexList = function (params) { return new Tinker({
    url: "/api/v2/index/list",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
var isCn = function () { return sessionStorage.getItem('isCn') === 'false' ? false : ''; };
export var fetchRealTimeStock = function (params) { return new Tinker({
    url: "/api/v2/capital/realTime?isCN=" + isCn(),
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 北向资金和涨跌统计
 */
export var fetchNorthAndRfStat = function () { return new Tinker({
    url: '/api/v2/index/northAndRfStat',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 * 首页北向两月
 */
export var northHostory = function () { return new Tinker({
    url: '/api/v2/capital/northHostory',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 * 首页北向一天
 */
export var northToday = function () { return new Tinker({
    url: '/api/v2/capital/northToday',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 * 当前登录用户信息
 */
export var getUserinfo = function (params) {
    var pathName = window.location.pathname;
    //不弹7天vip弹框的路由数组
    var notMskArr = ['/integralNewUser'];
    return (new Tinker({
        url: "/api/v2/user/userinfo",
        header: { method: 'GET' },
        dataType: 'json',
        data: __assign(__assign({}, params), { source: '1', action: notMskArr.includes(pathName) ? 'notMsk' : null }),
    }));
};
/**
 * 大V数据统计
 */
// export const getVdataPoint = (params: { userId: number }) => new Tinker({
//     url: `/api/v2/V/dataPoint`,
//     header: { method: 'GET' },
//     dataType: 'json',
//     data: params,
// });
export var getVdataPoint = function () { return new Tinker({
    url: "/api/v2/V/dataPoint",
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getFilters = function (params) { return new Tinker({
    url: "/api/v2/capital/screen",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getFiltersNum = function (params) { return new Tinker({
    url: "/api/v2/capital/screen/num",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getVipType = function () { return new Tinker({
    url: "/api/v2/user/vipType",
    header: { method: 'GET' },
    dataType: 'json',
}); };
// export const getVipType = () => new Tinker({
//     url: `/api/v2/user/vipType_v2`,
//     header: { method: 'GET' },
//     dataType: 'json',
// });
export var getGoodsList = function (params) { return new Tinker({
    url: "/api/v2/goods/list_v3",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var wxPay = function (params) {
    var routeArr = store.getState().routeLast_pathName;
    upSavePointer([
        { name: 'event_name', value: '支付' },
        { name: 'event_ext1', value: '发起支付' },
        { name: 'event_ext2', value: params.goodId },
        { name: 'event_ext3', value: routeArr && routeArr.length > 1 ? routeArr[routeArr.length - 2] : null },
    ]);
    return new Tinker({
        url: "/api/v2/wx/pay",
        header: { method: 'GET' },
        dataType: 'json',
        data: params,
    });
};
export var getTagChange = function (params) { return new Tinker({
    url: "/api/v2/capital/tagChange",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getBanner = function (params) { return new Tinker({
    url: "/api/v2/banner/list",
    header: { method: 'GET' },
    dataType: 'json',
    data: params ? params : {},
}); };
export var getChargeDate = function () { return new Tinker({
    url: "/api/v2/config/get?key=chargeDate",
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var unlockStock = function (params) { return new Tinker({
    url: "/api/v2/capital/unLock",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 开关微信推送总开关
 * @param params
 */
export var pushChangeVX = function (params) { return new Tinker({
    url: "/api/v2/pricePush/setChange",
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 开关新加自选股推送总开关
 * @param params
 */
export var pushChangeNewAdd = function (params) { return new Tinker({
    url: "/api/v2/pricePush/setAutoChange",
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 查询用户的新加自选股列表
 * @param params
 */
export var pushGetAllList = function () { return new Tinker({
    url: "/api/v2/pricePush/pricePushlist",
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 * 获取自选股推送设置
 * @param params
 */
export var pushGet = function (params) { return new Tinker({
    url: "/api/v2/pricePush/pushInfo",
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *
 * @param params code码以及列表数组
 */
export var upDatePriceSet = function (params) { return new Tinker({
    url: "/api/v2/pricePush/batchAdd?code=" + params.code,
    header: { method: 'POST' },
    dataType: 'json',
    data: params.data
}); };
/**
 * 新增一个推送设置
 * @param params
 */
export var pushSet = function (params) { return new Tinker({
    url: "/api/v2/pricePush/add",
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 删除一个推送设置
 * @param params
 */
export var pushDelete = function (params) { return new Tinker({
    url: "/api/v2/pricePush/deleteByCode",
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * rps对比
 * @param params
   capitalOrIndex: 股票 capital ，指数index
*/
export var rpsCompareCode = function (params) { return new Tinker({
    url: "/api/v2/capital/rps",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 根据股票code获取股票名称
 * @param params
 */
export var queryStockName = function (params) { return new Tinker({
    url: "/api/v2/capital/queryName",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 同二级行业股票
 * @param params
 */
export var sameIndustry = function (params) { return new Tinker({
    url: "/api/v2/capital/sameIndustry",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 获取文章列表（支持分页）
 * @param {*} params
 */
export var getArticleList = function (params) { return new Tinker({
    url: "/api/v2/article/list",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 获取文章详情
 * @param {*} params
 */
export var getArticleInfo = function (params) { return new Tinker({
    url: "/api/v2/article/get",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 前端页面埋点
 * @param {*} params
 */
export var pvSave = function (params) { return new Tinker({
    url: "/api/v2/pv/save",
    data: params,
    header: { method: 'GET' }
}); };
/**
 *
 * @param {*} params
 */
export var getOptGroupList = function () { return new Tinker({
    url: "/api/v2/opt/optGroupList",
    header: { method: 'GET' }
}); };
/**
 *
 * @param {*} params
 */
export var getOptGroupListV2 = function () { return new Tinker({
    url: "/api/v2/opt/optGroupListV2",
    header: { method: 'GET' }
}); };
/**
 * 分组列表
 * @param {*} params
 */
export var getlistOpt = function (params) { return new Tinker({
    url: "/api/v2/opt/listOptV4",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 新增分组
 * @param {*} params
 */
export var addOptGroup = function (params) { return new Tinker({
    url: "/api/v2/opt/addOptGroup",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 修改分组名
 * @param {*} params
 */
export var renameOptGroup = function (params) { return new Tinker({
    url: "/api/v2/opt/renameOptGroup",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 删除分组
 * @param {*} params
 */
export var delOptGroup = function (params) { return new Tinker({
    url: "/api/v2/opt/delOptGroup",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 股票分组排序
 * @param {*} params
 */
export var sortOptGroup = function (params) { return new Tinker({
    url: "/api/v2/opt/sortOptGroup",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 策略分组排序
 * @param {*} params
 */
export var sortOptGroup2 = function (params) { return new Tinker({
    url: "/api/v2/opt/sortOptGroupV2?optType=strategy",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 分组排序
 * @param {*} params
 */
export var batchDelOpt = function (params) { return new Tinker({
    url: "/api/v2/opt/batchDelOpt?groupId=" + (params.groupId ? params.groupId : ''),
    header: { method: 'POST' },
    dataType: 'json',
    data: params.data,
}); };
/**
 * 移动自选股到别的分组
 * @param {*} params
 */
export var moveOpt = function (params) { return new Tinker({
    url: "/api/v2/opt/moveOpt",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 移动自选股到别的分组
 * @param {*} params
 */
export var addOpt = function (params) { return new Tinker({
    url: "/api/v2/opt/addOpt",
    data: params,
    header: { method: 'GET' }
}); };
// /api/v2/opt/batchDelOpt
export var delOpts = function (codes) { return new Tinker({
    url: "/api/v2/opt/batchDelOpt",
    header: { method: 'POST' },
    dataType: 'json',
    data: codes,
}); };
/**
 * 股票所在自选股id列表
 * @param {*} params
 */
export var getGroupIds = function (params) { return new Tinker({
    url: "/api/v2/opt/groupIds",
    data: params,
    header: { method: 'GET' }
}); };
// 股票所在分组id
export var getCodesGroup = function (codes) { return new Tinker({
    url: '/api/v2/opt/groupIdsMultiCodes',
    data: { codes: codes },
    header: { method: 'GET' }
}); };
// 批量修改自选分组-改
export var changeOptStatus = function (params) { return new Tinker({
    url: '/api/v2/opt/changeOpt',
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 子行业指数列表
 * @param {*} params
 */
export var getIndexChildren = function (params) { return new Tinker({
    url: "/api/v2/index/childs",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 公告文件列表
 * @param {*} params
 */
export var noticeListById = function (params) { return new Tinker({
    url: "/api/v2/notice/listFileById",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 业绩报告列表
 * @param {*} params
 */
export var noticeListByCode = function (params) { return new Tinker({
    url: "/api/v2/notice/listByCode",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 用户的研报列表
 * @param {*} params
 */
export var userReports = function (params) { return new Tinker({
    url: "/api/v2/report/userReports",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 股票的研报列表
 * @param {*} params
 */
export var reportListByCode = function (params) { return new Tinker({
    url: "/api/v2/report/list",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 分析师介绍
 * @param {*} params
 */
export var reportAuthor = function (params) { return new Tinker({
    url: "/api/v2/report/author",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 【详情排序】获取
 * @param {*} params
 */
export var getModuleSort = function () { return new Tinker({
    url: "/api/v2/optSort/get",
    header: { method: 'GET' }
}); };
/**
 * 港股【详情排序】获取
 * @param {*} params
 */
export var getModuleSorthk = function () { return new Tinker({
    url: "/api/v2/optSort/getHk",
    header: { method: 'GET' }
}); };
/**
 * 【详情排序】获取
 * @param {*} params
 */
export var getModuleSet = function (params) { return new Tinker({
    url: "/api/v2/optSort/set",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 杜邦分析
 * @param {*} params
 */
export var getDubont = function (params) { return new Tinker({
    url: "/api/v2/quarter/profitability",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getSameIndustry = function (params) { return new Tinker({
    url: "/api/v2/capital/sameIndustry2",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getHKSameIndustry = function (params) { return new Tinker({
    url: "/api/v2/hk/module/sameIndustry2",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 周报详情
 * @param {*} params
 */
export var getWeekly = function (params) { return new Tinker({
    url: "/api/v2/weekly/get",
    header: { method: 'GET' },
    data: params,
}); };
/**
 * 用户的业绩报告列表
 * @param {*} params
 */
export var userNoices = function () { return new Tinker({
    url: "/api/v2/notice/userNoices",
    header: { method: 'GET' },
}); };
/**
 * 获取验证码
 * @param params
 */
export var getSmsCode = function (params) { return new Tinker({
    url: "/api/v2/user/getSmsCode",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 绑定手机号
 * @param params
 */
export var bindPhone = function (params) { return new Tinker({
    url: "/api/v2/user/bindPhone",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 用户推送管理设置
 * @param params
 */
export var notificationSet = function (params) { return new Tinker({
    url: "/api/v2/user/pushSet",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
export var weeklyPV = function (params) { return new Tinker({
    url: "/api/v2/pv/save?page=\u5468\u62A5\u63A8\u8350\u6587\u7AE0&path=weekly/artcle&method=GET&params=" + params.pushDate,
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getCashFlow = function (params) { return new Tinker({
    url: "/api/v2/cashFlow/get",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getdividend = function (params) { return new Tinker({
    url: "/api/v2/dividend/get",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 错误信息
 * @param params
 */
export var errorLog = function (params) {
};
// new Tinker({
//     url: `/api/v2/up/log/error`,
//     header: { method: 'POST' },
//     dataType: 'json',
//     data: params,
// });
/**
 * 行业指数
 * @param params
 */
export var getindustrys = function () { return new Tinker({
    url: "/api/v2/index/industrys",
    header: { method: 'GET' },
    dataType: 'json',
    data: {},
}); };
/**
 * 选股
 * @param params
 */
export var selectStock = function (params) { return new Tinker({
    url: "/api/v2/select/select",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 选股数量
 * @param params
 */
export var selectStockNum = function (params) { return new Tinker({
    url: "/api/v2/select/select/num",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 选股策略重命名
 * @param params
 */
export var renameSelect = function (params) { return new Tinker({
    url: "/api/v2/userSelectStrategy/rename",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 基金策略重命名
 * @param params
 */
export var fundrenameSelect = function (params) { return new Tinker({
    url: "/api/v2/fund/userSelectStrategy/rename",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 我的选股列表
 * @param params
 */
export var selectList = function () { return new Tinker({
    url: "/api/v2/userSelectStrategy/list",
    header: { method: 'GET' },
    dataType: 'json'
}); };
/**
 * 删除我的策略
 * @param params
 */
export var selectdel = function (params) { return new Tinker({
    url: "/api/v2/userSelectStrategy/del",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 删除我的基金策略
 * @param params
 */
export var fundselectdel = function (params) { return new Tinker({
    url: "/api/v2/fund/userSelectStrategy/del",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 选股范围
 * @param params
 */
export var selectRange = function (params) { return new Tinker({
    url: "/api/v2/select/range",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 选基金范围
 * @param params
 */
export var selectRangeFund = function (params) { return new Tinker({
    url: "/api/v2/fundSelect/range",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 选基金数量
 * @param params
 */
export var selectFundNum = function (params) { return new Tinker({
    url: "/api/v2/fundSelect/select/num",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 选基金
 * @param params
 */
export var fundSelect = function (params) { return new Tinker({
    url: "/api/v2/fundSelect/select",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 关注策略
 * @param params
 */
export var addStrategy = function (params) { return new Tinker({
    url: "/api/v2/userSelectStrategy/add",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 关注基金策略
 * @param params
 */
export var addFundStrategy = function (params) { return new Tinker({
    url: "/api/v2/fund/userSelectStrategy/add",
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 已关注自选股
 * @param params
 */
export var userCodes = function (params) { return new Tinker({
    url: "/api/v2/capital/userCodes",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 修改自选股
 * @param params
 */
export var updateCondition = function (params) { return new Tinker({
    url: "/api/v2/userSelectStrategy/updateCondition/" + params.id,
    header: { method: 'POST' },
    dataType: 'json',
    data: params.data,
}); };
/**
 * 修改策略基金
 * @param params
 */
export var updateFundCondition = function (params) { return new Tinker({
    url: "/api/v2/fund/userSelectStrategy/updateCondition/" + params.id,
    header: { method: 'POST' },
    dataType: 'json',
    data: params.data,
}); };
/**
 * 对Tags类型选择的ID替换自定义文案
 * @param data
 */
export var conditionAdjust = function (data) { return new Tinker({
    url: "/api/v2/select/conditionAdjust",
    header: { method: 'POST' },
    dataType: 'json',
    data: data,
}); };
/**
 * 芝士精选
 * @param params
 */
export var cheserecommend = function () { return new Tinker({
    url: '/api/v2/select/recommend',
    header: { method: 'GET' },
    dataType: 'json',
    data: {},
}); };
// https://stock.cheesefortune.com/api/v3/stockP/strategyChangeList?beginDate&sid=308828&size=100
// 策略修改记录
export var strategyChangeList = function (params) { return new Tinker({
    url: '/api/v3/stockP/strategyChangeList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// removeStrategyRedPoint
export var removeStrategyRedPoint = function (params) { return new Tinker({
    url: '/api/v3/stockP/removeStrategyRedPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// /api/v3/stockP/strategyVersion?sid=308829&version=1
// Request Method:
// GET
// 策略修改历史
export var strategyVersion = function (params) { return new Tinker({
    url: '/api/v3/stockP/strategyVersion',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 策略详情
 * @param params
 */
export var userSelectStrategyInfo = function (params) { return new Tinker({
    url: '/api/v2/userSelectStrategy/info/' + params.id,
    header: { method: 'GET' },
    dataType: 'json',
    data: {},
}); };
/**
 * 基金策略详情
 * @param params
 */
export var userSelectStrategyFundInfo = function (params) { return new Tinker({
    url: '/api/v2/fund/userSelectStrategy/info/' + params.id,
    header: { method: 'GET' },
    dataType: 'json',
    data: {},
}); };
/**
 * 运营能力
 * @param params
 */
export var quarterInfo = function (params) { return new Tinker({
    url: '/api/v2/quarter/operationCapability',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 资本结构
 * @param params
 */
export var capitalStructure = function (params) { return new Tinker({
    url: '/api/v2/quarter/capitalStructure_v2',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 华宝uid
 * @param params
 */
export var getZhishiUID = function (params) { return new Tinker({
    url: '/api/v2/open/getZhishiUID',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 华宝转芝士token
 * @param params
 */
export var transformToCheeseToken = function (params) { return new Tinker({
    url: '/api/v2/sechb/decr',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 埋点
 * @param params
 */
export var upSave = function (params) { return new Tinker({
    url: '/api/v2/up/save',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 订单
 * @param params
 */
export var orderList = function (params) { return new Tinker({
    url: '/api/v2/order/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 产业链
 * @param params
 */
export var industryChain = function (params) { return new Tinker({
    url: '/api/v2/quarter/industryChain',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 自选股股票置顶
 * @param params
 */
export var setTop = function (params) { return new Tinker({
    url: '/api/v2/opt/setTop',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 重置统计
 * @param params
 */
export var reSetAddDate = function (params) { return new Tinker({
    url: '/api/v2/opt/reSetAddDate',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 新增备注
 * @param params
 */
export var addRemark = function (code, params) { return new Tinker({
    url: "/api/v2/opt/remark/" + code,
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 * 自选股股票数量
 * @param params
 */
export var getOptGroupItemNum = function (params) { return new Tinker({
    url: "/api/v2/opt/optGroupItemNum",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 部落文章详情
 * @param params
 */
export var articleInfo = function (params) { return new Tinker({
    url: '/api/comm/article/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 大事提醒
 * @param params
 */
export var majorEvent = function (params) { return new Tinker({
    url: '/api/v2/quarter/majorEvent',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 大事提醒
 * @param params
 */
export var postsInfo = function (params) { return new Tinker({
    url: '/api/comm/posts/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 深度数据
 * @param params
 */
export var getDepthData = function (params) { return new Tinker({
    url: '/api/v2/quarter/depthData',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 * 港股深度数据
 * @param params
 */
export var getDepthDataHK = function (params) { return new Tinker({
    url: '/api/v2/hk/module/depthData',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// 日报列表
export var getDailyList = function () { return new Tinker({
    url: '/api/v2/weekly/dailyList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
// 周报列表
export var getWeeelyList = function () { return new Tinker({
    url: '/api/v2/weekly/weekList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 * get K
 * @param
 */
export var getKSet = function () { return new Tinker({
    url: '/api/v2/optSort/getKSet',
    header: { method: 'GET' },
    dataType: 'json'
}); };
/**
 * set K
 * @param params
 */
export var setKSet = function (params) { return new Tinker({
    url: '/api/v2/optSort/setKSet',
    header: { method: 'POST' },
    data: params,
}); };
/**
 * 基金投资亮点与风险
 * @param params
 */
export var getFund_highlightAndRisk = function (params) { return new Tinker({
    url: '/api/v2/fund/commentV2',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  基金业绩走势
 *  @param params
*/
export var getFund_performance = function (params) { return new Tinker({
    url: '/api/v2/fund/kTrendV2',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getFund_CompareDataAll = function (params) { return new Tinker({
    url: '/api/v2/fund/comapreModule',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  基金首屏数据
 *  @param params
*/
export var getFund_pageInfo = function (params) { return new Tinker({
    url: '/api/v2/fund/firstPageInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  基金后屏加载数据
 *  @param params
*/
export var getFund_otherPageInfo = function (params) { return new Tinker({
    url: '/api/v2/fund/otherPageInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  基金经理详情页
 *  @param params
*/
export var getFund_manager = function (params) { return new Tinker({
    url: '/api/v2/fund/manager/infos',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  基金经理详情页业绩走势
 *  @param params
*/
export var getFund_manager_performance = function (params) { return new Tinker({
    url: '/api/v2/fund/manager/kTrend',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  基金主页
 *  @param params
*/
export var getFundHome_Data = function () { return new Tinker({
    url: '/api/v2/fund/homePage',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  基金首页--蛋卷 codes=CSI2015,CSI1133
 *  @param params
*/
export var getFundHome_Data_Combination = function (str) { return new Tinker({
    url: "/api/v2/fund/danJuanCombination?codes=" + str,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  基金首页--蛋卷开关推送
 *  @param params
*/
export var getFundHome_Data_changeUserSubscribe = function () { return new Tinker({
    url: "/api/v2/fund/subscribeCombinationMessage",
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  首页查询新入选个数
 *  @param params
*/
export var getHomeNewSelect = function () { return new Tinker({
    url: '/api/v2/userSelectStrategy/unread',
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var fetchHotStock = function () { return new Tinker({
    url: '/api/v2/capital/hotCapCodes',
    header: { method: 'GET' },
}); };
/**
 *  策略是否有新股入选
 *  @param
 */
export var userSelectStrategyunread = function () { return new Tinker({
    url: '/api/v2/userSelectStrategy/unread',
    header: { method: 'GET' },
    dataType: 'json',
    data: '',
}); };
/**
 *  我的策略列表
 *  @param
 */
export var userSelectStrategyList = function () { return new Tinker({
    url: '/api/v2/userSelectStrategy/list_v2?time=' + new Date().getTime(),
    header: { method: 'GET' },
    dataType: 'json',
    data: '',
}); };
/**
 *  我的基金策略列表
 *  @param
 */
export var userSelectStrategyfundList = function (type) { return new Tinker({
    url: '/api/v2/fund/userSelectStrategy/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: { type: type ? type : 1, time: new Date().getTime() },
}); };
/**
 *  新年活动 ———— 发起拼团
 *  @param params
*/
export var openGroup_newYear = function (params) { return new Tinker({
    url: '/api/v2/group/open',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新年活动 ———— 查询拼团列表
 *  @param params
*/
export var getGroup_List = function () { return new Tinker({
    url: '/api/v2/group/groupList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  新年活动 ———— 查询具体团的信息
 *  @param params
*/
export var getGroupData = function (params) { return new Tinker({
    url: '/api/v2/group/groupInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新年活动 ———— 查询是否可以参团
 *  @param params
*/
export var getIsComeOnGroup = function (params) { return new Tinker({
    url: '/api/v2/group/join',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新年活动 ———— 支付
 *  @param params
*/
export var isGroupStage = function () { return new Tinker({
    url: '/api/v2/group/isGroupStage',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  新年活动 ———— 支付
 *  @param params
*/
export var payNewYearHappy = function (params) { return new Tinker({
    url: '/api/v2/wx/groupPay',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var fetchzhishiData = function () { return new Tinker({
    url: '/api/comm/posts/newUserList',
    header: { method: 'GET' },
}); };
/**
 *   获取停牌日期
 *  @param params
 */
export var stopTradeDate = function () { return new Tinker({
    url: '/api/v2/capital/stopTradeDate',
    header: { method: 'GET' },
}); };
/**
 *  股票详情————机构持股
 *  @param params
*/
export var getShreholderHolding = function (params) { return new Tinker({
    url: '/api/v2/quarter/instholdpctList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  机构要点
 *  @param params
 */
export var institutionPoint = function (params) { return new Tinker({
    url: '/api/v2/quarter/institutionPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  研报摘要
 *  @param params
 */
export var researchAbstractPoint = function (params) { return new Tinker({
    url: '/api/v2/report/researchAbstractPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  研报摘要 全量
 *  @param params
 */
export var fullResearchAbstractPoint = function (params) { return new Tinker({
    url: '/api/v2/report/fullResearchAbstractPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  研报详情
 *  @param params
 */
export var research = function (params) { return new Tinker({
    url: "/api/v2/report/research/" + params.id,
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  港股研报详情
 *  @param params
 */
export var researchK = function (params) { return new Tinker({
    url: "/api/v2/hk/report/research/" + params.id,
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  我的研报2
 *  @param params
 */
export var userReportsV2 = function (params) { return new Tinker({
    url: '/api/v2/report/userReportsV2',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  股票非对比模块
 *  @param params
 */
export var infoMoudule = function (params) { return new Tinker({
    url: '/api/v2/module/module',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  估值与分位
 *  @param params
 */
export var pepbMap = function (params) { return new Tinker({
    url: '/api/v2/module/pepbMap',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  pepbband
 *  @param params
 */
export var pepbband = function (params) { return new Tinker({
    url: '/api/v2/module/pepbband',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  股票详情————股票other接口
 *  @param params
 */
export var getStockOtherData = function (params) { return new Tinker({
    url: '/api/v2/module/module',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  股票详情————股票对比数据接口
 *  @param params
 */
export var getStockIncludeComparison = function (params) { return new Tinker({
    url: '/api/v2/module/compareModule',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// new 现金流量接口
export var getNewStockIncludeComparison = function (params) { return new Tinker({
    url: '/api/v3/data/getByconfig',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  榜单————股票目录
 *  @param params
 */
export var getStockRankNames = function () { return new Tinker({
    url: '/api/v2/capital/rank/getRankNames',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  榜单————基金目录
 *  @param params
 */
export var getFundRankNames = function () { return new Tinker({
    url: '/api/v2/fund/rank/getRankNames',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  榜单————股票/基金/经理 菜单结构
 *  @param params
 */
export var getRankLevel = function (params, type) { return new Tinker({
    url: type == 'stock' ? '/api/v2/capital/rank/getLevelTree' :
        type == 'fund' ? '/api/v2/fund/rank/getLevelTree' :
            type == 'manager' ? '/api/v2/fund/rank/manager/getLevelTree' : '',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  榜单————股票/基金/经理 菜单结构
 *  @param params
 */
export var getRankList = function (params, type) { return new Tinker({
    url: type == 'stock' ? '/api/v2/capital/rank/getRank' :
        type == 'fund' ? '/api/v2/fund/rank/getRank' :
            type == 'manager' ? '/api/v2/fund/rank/manager/getRank' : '',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// 涨跌模块升级
export var getRiseFallCapitals = function (params) { return new Tinker({
    url: '/api/v2/capital/riseFallCapitals',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
export var getPlateStat = function () { return new Tinker({
    url: '/api/v2/capital/plateStat',
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getPlateStatV2 = function () { return new Tinker({
    url: '/api/v2/capital/plateStatV2',
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getPlateStatV2Page = function (params) { return new Tinker({
    url: '/api/v2/capital/plateStatV2Page',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//板块详情最新接口
export var getPlateStatV3Page = function (params) { return new Tinker({
    url: '/api/v3/appMarket/plateStatV3',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//全球风向标 wind
export var getWind = function (params) { return new Tinker({
    url: '/api/v3/appMarket/globalWindVane',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//板块刷新
export var plateStatV2Items = function (params) { return new Tinker({
    url: '/api/v2/capital/plateStatV2Items?type=' + params.type,
    header: { method: 'POST' },
    dataType: 'json',
    data: params.codes,
}); };
//首页产业分析接口
export var hotIndustrial = function () { return new Tinker({
    url: '/api/v3/appMarket/hotIndustrial',
    header: { method: 'GET' },
    dataType: 'json',
}); };
//首页ETF查询分类接口
export var marketCatalogs = function () { return new Tinker({
    url: '/api/v3/appMarket/marketCatalogs',
    header: { method: 'GET' },
    dataType: 'json',
}); };
//首页ETF根据分类分页查询
export var getPaging = function (params) { return new Tinker({
    url: '/api/v3/appMarket/pageEtf',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//ETF看板全部分类
export var marketAllCatalogs = function () { return new Tinker({
    url: '/api/v3/appMarket/etfCatalogs',
    header: { method: 'GET' },
    dataType: 'json',
}); };
//  ETF看板根据分类分页查询 /api/v3/appMarket/pageEtf
export var getAllPaging = function (params) { return new Tinker({
    url: '/api/v3/appMarket/pageEtf',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
export var getMakeMoney = function () { return new Tinker({
    url: '/api/v2/capital/makeMoney',
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var marketSummary = function () { return new Tinker({
    url: '/api/v3/market/marketSummary',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  账号合并
 *  @param params
 */
export var activeMargeUser = function (params) { return new Tinker({
    url: '/api/v2/user/activeMargeUser',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新用户活动拼团抽奖————开团
 *  @param params
 */
export var newUserParty_creatGroup = function (params) { return new Tinker({
    url: '/api/v2/groupActivity/open',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新用户活动拼团抽奖————参团
 *  @param params
 */
export var newUserParty_addGroup = function (params) { return new Tinker({
    url: '/api/v2/groupActivity/join',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新用户活动拼团抽奖————参团记录
 *  @param params
 */
export var newUserParty_getGroupList = function () { return new Tinker({
    url: '/api/v2/groupActivity/groupList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  新用户活动拼团抽奖————单独团记录
 *  @param params
 */
export var newUserParty_getGroupIdInfo = function (params) { return new Tinker({
    url: '/api/v2/groupActivity/groupInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  新用户活动拼团抽奖————单独团记录
 *  @param params
 */
export var newUserParty_getPageIndex = function () { return new Tinker({
    url: 'api/v2/groupActivity/currentInfo',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  新用户活动拼团抽奖————活动开关
 *  @param params
 */
export var newUserParty_getMeMenus = function () { return new Tinker({
    url: 'api/v2/config/getAppMenus',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  新用户活动拼团抽奖————活动ICON
 *  @param params
 */
export var newUserParty_isShowIcon = function (params) { return new Tinker({
    url: 'api/v2/groupActivity/groupIcon',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  新用户活动拼团抽奖————入口Icon点击次数统计
 *  @param params
 */
export var newUserParty_vatalClick = function (params) { return new Tinker({
    url: 'api/v2/groupActivity/activityClick',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  新用户活动拼团抽奖————入口Icon点击次数统计
 *  @param params
 */
export var newUserParty_vatalClick_me = function (params) { return new Tinker({
    url: 'api/v2/config/appClick',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  指数详情V2
 *  @param params
 */
export var indexInfo = function (params) { return new Tinker({
    url: 'api/v2/index/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  指数详情V2 pepb
 *  @param params
 */
export var indexInfopepb = function (params) { return new Tinker({
    url: '/api/v2/index/pepb',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  指数详情V2 成分股列表
 *  @param params
 */
export var membersList = function (params) { return new Tinker({
    url: '/api/v2/index/membersList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  指数详情V2 相关基金列表
 *  @param params
 */
export var relationsList = function (params) { return new Tinker({
    url: '/api/v2/index/relationsList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  指数详情V2 child
 *  @param params
 */
export var indexChild = function (params) { return new Tinker({
    url: '/api/v2/index/childsV2',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  指数详情V2 蛋卷基金状态
 *  @param params
 */
export var getFundSale = function (params) { return new Tinker({
    url: '/api/v2/fund/getFundSale',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  上传阿里云
 *  @param params
 */
export var uploadImage_toAlY = function (url, params) { return new Tinker({
    url: url,
    header: { method: 'POST' },
    ContentType: 'multipart/form-data;',
    dataType: 'json',
    data: params,
}); };
export var getUpLoadImage_toALY_parameter = function () { return new Tinker({
    url: '/api/v2/oss/sign?dir=cheese/ocr',
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var getUpLoadImage_toALY2_parameter = function () { return new Tinker({
    url: '/api/v2/oss/signvideo?dir=cheese/',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  自选股上传文件
 *  @param params
 */
export var Favorite_uploadFile = function (params) { return new Tinker({
    url: '/api/v2/opt/imgToStocks',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
export var danjuan_question = function () { return new Tinker({
    url: '/api/v2/config/get?key=FUND_GROUP_QA_URL',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  可用优惠卷List
 *  @param params
 */
export var getCouponList = function (params) { return new Tinker({
    url: '/api/v2/coupon/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  过期优惠卷List
 *  @param params
 */
export var getCouponOverList = function (params) { return new Tinker({
    url: '/api/v2/coupon/listUsed',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  A_module_Subject 简评标签
 *  @param params
 */
export var getComment = function (params) { return new Tinker({
    url: '/api/v2/capital/getComment',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//选基返回数量
export var fundSelectNumItems = function (param) { return new Tinker({
    url: '/api/v2/fundSelect/selectNumItems',
    header: { method: 'POST' },
    dataType: 'json',
    data: param,
}); };
/**
 *  调查问卷 获取选项
 *  @param params
 */
export var getFeedback = function (params) { return new Tinker({
    url: '/api/v2/feedback/getFeedback',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  调查问卷 提交选项
 *  @param params
 */
export var uploadFeedback = function (param) { return new Tinker({
    url: '/api/v2/feedback/feedback',
    header: { method: 'POST' },
    dataType: 'json',
    data: param,
}); };
/**
 *  618活动 抽奖
 *  @param params
 */
export var chesseParty_luckDraw = function () { return new Tinker({
    url: '/api/v2/operate/userLottery',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  618活动 banner
 *  @param params banner展示类型: 1首页展示； 2股票详情页展示； 3周报展示； 4日报展示；默认为1
 */
export var getBannerList = function (param) { return new Tinker({
    url: '/api/v2/banner/getBannerInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  查询负面简评
 *  @param params
 */
export var commentTips = function (param) { return new Tinker({
    url: '/api/v2/capital/commentTips',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  北向明细
 *  @param params
 */
export var listNorth = function (param) { return new Tinker({
    url: '/api/v2/capital/listNorth',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  北向明细--行业
 *  @param params
 */
export var listNorthI = function (param) { return new Tinker({
    url: '/api/v2/capital/listNorthI',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  北向明细--行业--成分股
 *  @param params
 */
export var listNorthIMember = function (param) { return new Tinker({
    url: '/api/v2/capital/listNorthImember',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  南向明细
 *  @param params
 */
export var listSouthth = function (param) { return new Tinker({
    url: '/api/v2/capital/listSouth',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  南向明细--行业
 *  @param params
 */
export var listSouthI = function (param) { return new Tinker({
    url: '/api/v2/capital/listSouthI',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  南向明细--行业--成分股
 *  @param params
 */
export var listSouthIMember = function (param) { return new Tinker({
    url: '/api/v2/capital/listSouthImember',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  首页吸顶
 *  @param params
 */
export var getCeilingData = function () { return new Tinker({
    url: '/api/v2/config/get?key=Top_Content',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  弹框内容 包含 618弹框
 *  @param params
 */
export var getTempPopup = function () { return new Tinker({
    url: '/api/v2/banner/tempPopup',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  定投计算器——获取基金信息
 *  @param params
 */
export var getfundInfo_computer = function (params) { return new Tinker({
    url: '/api/v2/fund/calculator/fundInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  定投计算器——获取基金信息
 *  @param params
 */
export var getCalculator_computer = function (params) { return new Tinker({
    url: '/api/v2/fund/calculator',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  芝士全a
 *  @param params
 */
export var zhishiAllA = function (param) { return new Tinker({
    url: '/api/v2/capital/zhishiAllA',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  今日新股
 *  @param params
 */
export var stocksByIpoDate = function (param) { return new Tinker({
    url: '/api/v2/capital/stocksByIpoDate',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  搜索基金经理名称
 *  @param params
 */
export var getSearchManager = function (param) { return new Tinker({
    url: '/api/v2/fund/searchName',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  查询相同风格基金经理
 *  @param params
 */
export var getManagerStyle = function (param) { return new Tinker({
    url: '/api/v2/fund/searchByStyle',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  查询相同风格基金经理
 *  @param params
 */
export var getFundStyle = function (param) { return new Tinker({
    url: '/api/v2/fund/searchFundByStyle',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  查询相同风格基金经理
 *  @param params
 */
export var getFundManagerPk = function (param) { return new Tinker({
    url: '/api/v2/fund/fundManagerPk',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  基金模块详情
 *  @param params
 */
export var getfunCalc = function (param) { return new Tinker({
    url: '/api/v2/fund/getFundCalc',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  基金对比
 *  @param params
 */
export var fundPk = function (param) { return new Tinker({
    url: '/api/v2/fund/fundPk',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  基金模块详情
 *  @param params
 */
export var fundmodule = function (param) { return new Tinker({
    url: '/api/v2/fund/module',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  是否开启查看净值估算
 *  @param params
 */
export var handleFundCalc = function (param) { return new Tinker({
    url: '/api/v2/user/pushSet',
    header: { method: 'POST' },
    dataType: 'json',
    data: param,
}); };
/**
 *  基金公告
 *  @param params
 */
export var fundNotice = function (param) { return new Tinker({
    url: '/api/v2/fund/fundNotice',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  基金经理id
 *  @param params
 */
export var infosByIds = function (param) { return new Tinker({
    url: '/api/v2/fund/manager/infosByIds',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  基金经理id
 *  @param params
 */
export var queryKtrend = function (param) { return new Tinker({
    url: '/api/v2/fund/queryKtrend',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  问题列表
 *  @param params
 *  type	类型
 *  idx		最后一条记录的idx
 *  size	是
 *  msg     搜索内容
 */
export var getHelpList = function (param) { return new Tinker({
    url: '/api/v2/user/help/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  问题类型
 *  @param params
 */
export var getHelpAllTypes = function () { return new Tinker({
    url: '/api/v2/user/help/allTypes',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  问题详情
 *  @param params
 */
export var getHelpInfo = function (param) { return new Tinker({
    url: '/api/v2/user/help/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  课程 拓展阅读
 *  @param params
 */
export var getInfoExtlistId = function (param) { return new Tinker({
    url: '/api/comm/courseFront/getInfoExt',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  课程 课程 是否购买
 *  @param params
 */
export var getSubjectIsBuy = function () { return new Tinker({
    url: '/api/v2/courseActivity/isBuyCourse',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  课程 课程 是否参过团
 *  @param params
 */
export var getSubjectIsJoin = function (param) { return new Tinker({
    url: '/api/v2/courseActivity/joinGroupStatus',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  课程 课程 参团
 *  @param params
 */
export var getSubjectJoin = function (param) { return new Tinker({
    url: '/api/v2/courseActivity/join',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股信息
 *  @param params
 */
export var hkStockInfo = function (param) { return new Tinker({
    url: '/api/v2/hk/module/stock',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股非对比
 *  @param params
 */
export var hkmodule = function (param) { return new Tinker({
    url: '/api/v2/hk/module/module',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股汇率
 *  @param params
 */
export var exchangeRate = function (param) { return new Tinker({
    url: '/api/v2/hk/module/exchangeRate',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股对比汇率
 *  @param params
 */
export var exchangeRateAll = function (param) { return new Tinker({
    url: '/api/v2/hk/module/exchangeRateAll',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  首页北向
 *  @param params
 */
export var northInfo = function (param) { return new Tinker({
    url: '/api/v3/appMarket/hshkNorth',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
export var northInfo1 = function () { return new Tinker({
    url: '/api/v2/capital/northInfo',
    header: { method: 'GET' },
    dataType: 'json'
}); };
// 首页市场温度
export var marketTemperature = function (param) { return new Tinker({
    url: '/api/v3/appMarket/marketTemperature',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
// 首页产业查询
export var getSearchPdfImg = function (param) { return new Tinker({
    url: ' /api/v3/appMarket/searchPdfImg',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
//搜产业推荐卡
export var pdfSearchRecommend = function () { return new Tinker({
    url: '/api/v3/appMarket/pdfSearchRecommend',
    header: { method: 'GET' },
    dataType: 'json',
}); };
//产业分析目录
export var getCatalogsRemo = function () { return new Tinker({
    url: '/api/v3/appMarket/catalogs',
    header: { method: 'GET' },
    dataType: 'json',
}); };
//产业分析目录查询产业
export var getCatalogIndustrial = function (param) { return new Tinker({
    url: '/api/v3/appMarket/catalogIndustrial',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
//产业分析详情页
export var getIndustrialLink = function (param) { return new Tinker({
    url: '/api/v3/appMarket/industrialLink',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
//产业分析详情页跳转ETF相关
export var getIndustrialEtf = function (param) { return new Tinker({
    url: '/api/v3/appMarket/industrialEtf',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
//咨询 列表接口
export var getConsultationList = function (param) { return new Tinker({
    url: '/api/v2/news/jh/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
//咨询 详情接口
export var getConsultationListDetail = function (param) { return new Tinker({
    url: '/api/v2/news/jh/get',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
/**
 *  首页南向
 *  @param params
 */
export var southInfo = function () { return new Tinker({
    url: '/api/v2/capital/southInfo',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  首页查看策略
 *  @param params
 */
export var userSelectStrategy = function () { return new Tinker({
    url: '/api/v2/userSelectStrategy/list',
    header: { method: 'GET' },
    dataType: 'json',
}); };
export var hkCompareModule = function (param) { return new Tinker({
    url: '/api/v2/hk/module/compareModule',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
export var gethkdividend = function (param) { return new Tinker({
    url: '/api/v2/hk/module/cashDividend',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股研报
 *  @param params
 */
export var hkresearchAbstractPoint = function (param) { return new Tinker({
    url: '/api/v2/hk/report/researchAbstractPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股列表
 *  @param params
 */
export var institutionPointHK = function (param) { return new Tinker({
    url: '/api/v2/hk/module/institutionPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股列表
 *  @param params
 */
export var fullResearchAbstractPointHK = function (param) { return new Tinker({
    url: '/api/v2/hk/report/fullResearchAbstractPoint',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  课程 课程 填写地址 / 获取地址
 *  @param params
 */
export var getSubjectAddress = function (param) { return new Tinker({
    url: '/api/v2/courseActivity/createExpressInfo',
    header: { method: 'POST' },
    dataType: 'json',
    data: param,
}); };
/**
 *  课程 课程 填写地址 / 获取地址
 *  @param params
 */
export var getSubjecQrcode = function () { return new Tinker({
    url: '/api/v2/courseActivity/getQrcode',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  课程 课程 章节信息
 *  @param params
 */
export var getSubjecCourseIndex = function (param) { return new Tinker({
    url: '/api/comm/courseFront/courseIndex',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股是否显示季报
 *  @param params
 */
export var showQuarter = function (param) { return new Tinker({
    url: '/api/v2/hk/module/showQuarter',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  港股设置
 *  @param params
 */
export var setHKModuleSet = function (params) { return new Tinker({
    url: '/api/v2/optSort/setHk',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 *  港股 大事研报
 *  @param params
 */
export var getNoticeFiles = function (param) { return new Tinker({
    url: '/api/v2/hk/module/getNoticeFiles',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  国庆活动领取优惠券
 *  @param params
 */
export var getOptAvg = function (type, param) { return new Tinker({
    url: "api/v2/opt/avgChg?subColumn=" + type,
    header: { method: 'POST' },
    dataType: 'json',
    data: param,
}); };
/**
 *  国庆活动领取优惠券
 *  @param params
 */
export var join1001Offer = function (param) { return new Tinker({
    url: '/api/v2/coupon/offer',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  国庆活动领取优惠券
 *  @param params
 */
export var get1001CheckStatus = function () { return new Tinker({
    url: '/api/v2/coupon/getCheckStatus',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  投票
 *  @param params
 */
export var searchVoteV3 = function (param) { return new Tinker({
    url: '/api/v2/vote/searchV3',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
/**
 *  投票List
 *  @param params
 */
export var getVoteList = function (param) { return new Tinker({
    url: '/api/v2/vote/getList',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
/**
 *  投票操作
 *  @param params
 */
export var handleVote = function (param) { return new Tinker({
    url: '/api/v2/vote/vote',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
/**
 *  投票操作
 *  @param params
 */
export var getVoteFilterList = function () { return new Tinker({
    url: '/api/v2/vote/getFilterList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  投票获取最新的Id
 *  @param params
 */
export var getActivityId = function () { return new Tinker({
    url: '/api/v2/vote/getActivityId',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  投票获取最新的Id
 *  @param params
 */
export var getVoteListOver = function (param) { return new Tinker({
    url: '/api/v2/vote/getVoteList',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
/**
 *  自选 -- 净值估算
 *  @param params
 */
export var getFundCalcList = function (param) { return new Tinker({
    url: '/api/v2/fund/getFundCalcList',
    header: { method: 'POST' },
    dataType: 'json',
    data: param
}); };
/**
 *  自选 公告
 *  @param params
 */
export var getUserNoicesByPage = function (param) { return new Tinker({
    url: '/api/v2/notice/userNoicesByPage',
    header: { method: 'GET' },
    dataType: 'json',
    data: param
}); };
/**
 *  基金经理简评
 *  @param params
 */
export var managerComment = function (param) { return new Tinker({
    url: '/api/v2/fund//manager/comment',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  换手率 流通股本股本
 *  @param params
 */
export var floatShareList = function (param) { return new Tinker({
    url: '/api/v2/capital/floatShareList/',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  重要股东明细
 *  @param params
 */
export var getFundHolderDetails = function (param) { return new Tinker({
    url: '/api/v2/module/getFundHolderDetails',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  a股重要股东明细
 *  @param params
 */
export var getHolderDetails = function (param) { return new Tinker({
    url: '/api/v2/module/getHolderDetails',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  重要股东明细港股
 *  @param params
 */
export var getHolderDetailsHk = function (param) { return new Tinker({
    url: '/api/v2/hk/module/getHolderDetails',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  公募基金港股
 *  @param params
 */
export var getFundHolderDetailsHk = function (param) { return new Tinker({
    url: '/api/v2/hk/module/getFundHolderDetails',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  IOS订阅用户
 *  @param params
 */
export var getIosSubscribe = function () { return new Tinker({
    url: '/api/v2/ios/iosSubscribe',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  课程活动分享文案
 *  @param params
 */
export var getSubjectShareContent = function () { return new Tinker({
    url: 'api/v2/courseActivity/getShareContent',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  课程活是否发放优惠券
 *  @param params
 */
export var sendNoticeMsgCoupon = function (param) { return new Tinker({
    url: '/api/v2/courseActivity/sendNoticeMsg',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  我的礼品卡List
 *  @param params
 */
export var getMyGiftCardList = function (param) { return new Tinker({
    url: '/api/v2/card/user/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  赠送礼品卡
 *  @param params
 */
export var giveGiftCard = function (param) { return new Tinker({
    url: '/api/v2/card/user/giveCard',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  查询单个礼品卡信息
 *  @param params
 */
export var getGiftCardItem = function (param) { return new Tinker({
    url: '/api/v2/card/user/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  领取礼品卡
 *  @param params
 */
export var receiveGiftCard = function (param) { return new Tinker({
    url: '/api/v2/card/user/receiveCard',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  礼品卡商品列表
 *  @param params
 */
export var getGiftCardSaleList = function () { return new Tinker({
    url: '/api/v2/card/user/saleList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  节活动信息
 *  @param params
 */
export var getCheesePartyInfo = function (params) { return new Tinker({
    url: '/api/v2/activity/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  活动用户状态
 *  @param params
 */
export var getCheesePartyUserStatus = function (params) { return new Tinker({
    url: '/api/v2/raffle/userStatus',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// 1：活动用户状态		/api/v2/activity/userStatus?activityId=36
export var getCheesePartyUserStatus2 = function (params) { return new Tinker({
    url: '/api/v2/activity/userStatus',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//2：领取免费会员	/api/v2/user/receiveVip?flag=activity2411
export var getCheesePartyGiveVip2 = function (params) { return new Tinker({
    url: '/api/v2/user/receiveVip',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  节用户领取会员
 *  @param params
 */
export var getCheesePartyGiveVip = function () { return new Tinker({
    url: '/api/v2/raffle/giveVip',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  节商品列表
 *  @param params
 */
export var getCheesePartyGoodsList = function (params) { return new Tinker({
    url: '/api/v2/activity/activityGoods',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  3年会员节商品列表
 *  @param params
 */
export var getCheesePartyGoodsList3Year = function (params) { return new Tinker({
    url: '/api/v2/goods/list_v3',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  节跑马灯列表
 *  @param params
 */
export var getCheesePartyHorseLamp = function (params) { return new Tinker({
    url: '/api/v2/raffle/horseLamp',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  节抽奖
 *  @param params
 */
export var getCheesePartyRaffle = function (params) { return new Tinker({
    url: '/api/v2/raffle/raffle',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  节奖品列表
 *  @param params
 */
export var getCheesePartyUserAwardList = function (params) { return new Tinker({
    url: '/api/v2/raffle/userAwardList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  节奖品列表
 *  @param params
 */
export var getCheesePartyQrCode = function (params) { return new Tinker({
    url: '/api/v2/raffle/activityQrCode',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  节填写收货地址
 *  @param params
 */
export var createCheesePartyExpressInfo = function (params) { return new Tinker({
    url: '/api/v2/raffle/createExpressInfo',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 *  是否可购买会员时长
 *  @param params
 */
export var getUserCheckBuy = function () { return new Tinker({
    url: '/api/v2/goods/checkBuy',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  新股票对比
 *  @param params
 */
export var capitalPkFirstPage = function (param) { return new Tinker({
    url: '/api/v2/module/capitalPkFirstPage',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  新股票对比 走势
 *  @param params
 */
export var capitalPkKtrend = function (param) { return new Tinker({
    url: '/api/v2/module/capitalPkKtrend',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  新股票对比 其他
 *  @param params
 */
export var capitalPkOtherPage = function (param) { return new Tinker({
    url: '/api/v2/module/capitalPkOtherPage',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  公告聚合 list
 *  @param params
 */
export var getNoticeList = function (param) { return new Tinker({
    url: '/api/v2/capital/getNoticeList',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  公告聚合 是否存在重要报告
 *  @param params
 */
export var getNoticeImportant = function (param) { return new Tinker({
    url: '/api/v2/capital/getNoticeImportant',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  HK公告聚合 list
 *  @param params
 */
export var getNoticeListHK = function (param) { return new Tinker({
    url: '/api/v2/hk/module/getNoticeList',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  修改用户昵称
 *  @param params
 */
export var editUserInfoName = function (param) { return new Tinker({
    url: '/api/v2/user/editUserInfo',
    header: { method: 'POST' },
    dataType: 'json',
    data: param,
}); };
/**
 *  新股票对比 hk
 *  @param params
 */
export var capitalPkFirstPagehk = function (param) { return new Tinker({
    url: '/api/v2/hk/module/capitalPkFirstPage',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  新股票对比 走势hk
 *  @param params
 */
export var capitalPkKtrendhk = function (param) { return new Tinker({
    url: '/api/v2/hk/module/capitalPkKtrend',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  新股票对比 其他hk
 *  @param params
 */
export var capitalPkOtherPagehk = function (param) { return new Tinker({
    url: '/api/v2/hk/module/capitalPkOtherPage',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  选股器
 */
export var listSelectInfo = function (param) { return new Tinker({
    url: '/api/v2/select/listSelectInfo',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  概念
 */
export var allConcept = function () { return new Tinker({
    url: '/api/v2/index/allConcept',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  选股器亮点风险标签 并且
 */
export var listCommentTagsApi = function (param) { return new Tinker({
    url: '/api/v2/select/listCommentTags?type=' + param.type,
    header: { method: 'GET' },
    dataType: 'json',
    param: param
}); };
/**
 *  领取优惠券
 *  @param params
 */
export var getCourseOffer = function (param) { return new Tinker({
    url: '/api/v2/coupon/courseOffer',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  股票详情页-获取股东户数详情
 *  @param params
 */
export var getStockHolderNumMap = function (param) { return new Tinker({
    url: 'api/v2/module/holderNumMap',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  大事提醒模块
 *  @param params
 */
export var getEventReminderList = function (param) { return new Tinker({
    url: 'api/v2/eventReminder/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  日报推送
 *  @param params
 */
export var getUserReportDaily = function (param) { return new Tinker({
    url: 'api/v2/userReport/daily',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  app 刷新机制
 *  @param params
 */
export var getReopenToken = function (param) { return new Tinker({
    url: '/api/v2/ios/reopenToken/get',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  获取日报新闻
 *  @param params
 */
export var getDailyNews = function (param) { return new Tinker({
    url: '/api/v2/userReport/daily/news',
    header: { method: 'GET' },
    dataType: 'json',
    data: param,
}); };
/**
 *  获取日报新闻
 *  @param params
 */
export var getDailyList_new = function () { return new Tinker({
    url: '/api/v2/userReport/daily/list',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  校验token 如token 合适 允许登陆
 *  @param params
 */
export var checkToken = function (params) { return new Tinker({
    url: '/api/v2/config/checkTestToken',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  是否ab 白名单
 *  @param params
 */
export var abtest = function () { return new Tinker({
    url: '/api/v2/user/abtest/tag?tag=20220422',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  获取 日K数据http
 *  @param params
 */
export var getdayK = function (params) { return new Tinker({
    url: 'api/v2/k/dayK',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 新-日K数据http
 *  @param params
 */
export var newgetdayK = function (params) { return new Tinker({
    url: 'api/v2/k/dayKV2',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
*  获取股价推送列表
*  @param params
*/
export var listForUserSwitch = function (params) { return new Tinker({
    url: '/api/v2/pricePush/listForUserSwitch',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *  默认推送设置
 *  @param params
 */
export var userSwitchEdit = function (params) { return new Tinker({
    url: '/api/v2/userSwitch/edit',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/**
 *  批量修改推送设置
 *  @param params
 */
export var batchUpdate = function (params) { return new Tinker({
    url: '/api/v2/pricePush/batchUpdate',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/**
 *  删除推送设置
 *  @param params
 */
export var delForUserSwitch = function (params) { return new Tinker({
    url: '/api/v2/pricePush/delForUserSwitch',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/**
 *  获取用户设置
 *  @param params
 */
export var switchList = function () { return new Tinker({
    url: '/api/v2/userSwitch/switchList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  获取新用户引导进度
 *  @param params
 */
export var getNewUserViwe = function () { return new Tinker({
    url: '/api/v2/newUser/isView',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  点赞和踩
 *  @param params
 */
export var getLikeOrDown = function (params) { return new Tinker({
    url: 'api/v2/newUser/getOptType',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  点赞和踩
 *  @param params
 */
export var handlLikeOrDown = function (params) { return new Tinker({
    url: 'api/v2/newUser/likeOrDown',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 *  同步新手任务
 *  @param params
 */
export var getNewUserSyncTask = function (params) { return new Tinker({
    url: 'api/v2/newUser/syncTask',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  查询股票是否在自选股
 *  @param params
 */
export var getCodeIsOpt = function (params) { return new Tinker({
    url: '/api/v2/opt/isOpt',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  查询股票是否在自选股
 *  @param params
 */
export var getNewUserLotteryInfo = function () { return new Tinker({
    url: '/api/v2/newUser/lotteryInfo',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  查询股票是否在自选股
 *  @param params
 */
export var getNewUserGetGift = function () { return new Tinker({
    url: 'api/v2/newUser/getGift',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  宝典 获取
 *  @param params
 */
export var getNewArticleList = function (params) { return new Tinker({
    url: 'api/v2/article/baoDian/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  复制别人的策略
 *  @param params
 */
export var copyStrategy = function (params) { return new Tinker({
    url: 'api/v2/userSelectStrategy/copyStrategy',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 产业分析 价格走势
 *  @param params
 */
export var getIndustrialChainSpotKList = function (params) { return new Tinker({
    url: 'api/v2/IndustrialChain/spotKList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 实时价格Http
 *  @param params
 */
export var getKrealTime = function (params) { return new Tinker({
    url: 'api/v2/k/subscribeRealTime',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 实时缩略图Http
 *  @param params
 */
export var getKsimpleChart = function (params) { return new Tinker({
    url: 'api/v2/k/subscribeShareMultiCodes',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 分时数据http
 *  @param params
 */
export var getKtimeData = function (params) { return new Tinker({
    url: 'api/v2/k/subscribeShare',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 新闻播报列表
 *  @param params
 */
export var getNewsCastList = function (params) { return new Tinker({
    url: 'api/v2/news/list',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 新闻播报更新的播报
 *  @param params
 */
export var getNewsCastcheckNews = function (params) { return new Tinker({
    url: 'api/v2/news/checkNews',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  获取 用户自选股情况
 *  @param params
 */
export var getUserOptCount = function () { return new Tinker({
    url: '/api/v2/opt/userOptCount',
    header: { method: 'POST' },
    dataType: 'json',
}); };
/**
 *  新闻播报更新的详情
 *  @param params
 */
export var getNewsItem = function (params) { return new Tinker({
    url: '/api/v2/news/get',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *   请求详情页付费模块
 *  @param params
 */
export var getStockCapital = function (params) { return new Tinker({
    url: '/api/v2/capital/vinfo',
    header: { method: 'POST' },
    dataType: 'json',
    data: params,
}); };
/**
 *   618活动-商品列表
 *  @param params
 */
export var getParty618_SaleList = function () { return new Tinker({
    url: '/api/v2/operate/saleList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *   618活动-开团
 *  @param params
 */
export var getParty618_creatGroup = function () { return new Tinker({
    url: '/api/v2/operate/cUnLock',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *   618活动-获取试听列表
 *  @param params
 */
export var getParty618_trialLesson = function (params) { return new Tinker({
    url: '/api/comm/courseFront/trialLesson',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *   618活动-抽奖信息
 *  @param params
 */
export var getParty618_LotteryInfo = function () { return new Tinker({
    url: '/api/v2/operate/userLotteryNum',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *   618活动-抽奖
 *  @param params
 */
export var getParty618_userLottery = function () { return new Tinker({
    url: '/api/v2/operate/userLottery',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *   618活动-抽奖
 *  @param params
 */
export var getParty618_unLock = function (params) { return new Tinker({
    url: '/api/v2/operate/unLock',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *   查询是否在活动期间
 *  @param params
 */
export var getIsCheesePartyTime = function (params) { return new Tinker({
    url: '/api/v2/operate/getActivity',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 *   埋点
 *  @param params
 */
export var uploadSaveCode = function (params) { return new Tinker({
    url: '/api/v2/up/save2',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/*   选股策略
*  @param params
*/
export var userSelectStrategy_v3 = function (params) { return new Tinker({
    url: '/api/v2/userSelectStrategy/list_v3',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//接口详情 (id: 451)
// 接口名称所有入选用户策略的股票
// 请求类型get
// 请求Url /api/v3/stockP/allStrategiesStocks
/**
 * 获取所有入选用户策略的股票
 */
export var getAllStrategiesStocks = function (params) { return new Tinker({
    url: '/api/v3/stockP/allStrategiesStocks',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//接口详情 (id: 450)
// 接口名称个股入选用户的策略
// 请求类型get
// 请求Url /api/v3/stockP/stockStrategysDetail
/**
 * 获取个股入选用户的策略
 * @param params
 */
export var getStockStrategysDetail = function (params) { return new Tinker({
    url: '/api/v3/stockP/stockStrategysDetail',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/*  积分模块 —— 获取基本信息
*  @param params
*/
export var getIntegralInfo = function () { return new Tinker({
    url: '/api/v2/pointShop/info',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  积分模块 —— 领取积分
*  @param params
*/
export var getIntegralReceive = function () { return new Tinker({
    url: '/api/v2/pointShop/receive',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  积分模块 —— 是否签到
*  @param params
*/
export var getIntegralIsSign = function () { return new Tinker({
    url: '/api/v2/pointShop/hasSign',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  积分模块 —— 商品列表
*  @param params
*/
export var getIntegralGoodList = function () { return new Tinker({
    url: '/api/v2/pointShop/goodList',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  积分模块 —— 兑换
*  @param params
*/
export var handleIntegralEx = function (params) { return new Tinker({
    url: ' /api/v2/pointShop/exchange',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/*  积分模块 —— 获取最新的地址信息
*  @param params
*/
export var getUserExpressLast = function () { return new Tinker({
    url: '/api/v2/express/last',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  积分模块 —— 积分明细
*  @param params
*/
export var getIntegralPointList = function (params) { return new Tinker({
    url: '/api/v2/pointShop/pointList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/*  积分模块 —— 兑换记录
*  @param params
*/
export var getIntegralExchangeList = function (params) { return new Tinker({
    url: '/api/v2/pointShop/exchangeList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/*  积分模块 —— 领取积分
*  @param params
*/
export var receiveIntegral = function (params) { return new Tinker({
    url: '/api/v2/pointShop/receive',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/*  积分模块 —— 是够签到
*  @param params
*/
export var getHasSign = function () { return new Tinker({
    url: '/api/v2/pointShop/hasSign',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  开户 —— 是否已开户
*  @param params
*/
export var getStockAccountIsOpenUser = function () { return new Tinker({
    url: '/api/v2/sec/isOpenUser',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  开户活动 —— 获取华西开户状态
*  @param params
*/
export var getStockAccounthxUserInfo = function () { return new Tinker({
    url: '/api/v2/secHx/hxUserInfo',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  开户活动 —— 领取福利
*  @param params
*/
export var getStockAccountWelfare = function (params) { return new Tinker({
    url: '/api/v2/secHx/getWelfare',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/*  开户活动 ——切换福利
*  @param params
*/
export var getStockAccountHandleWelfare = function (params) { return new Tinker({
    url: '/api/v2/secHx/editWelfare',
    header: { method: 'POST' },
    dataType: 'json',
    data: params
}); };
/*  开户活动 ——激活福利
*  @param params
*/
export var getStockAccountAvtiWelfare = function () { return new Tinker({
    url: '/api/v2/secHx/actiWelfare',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  开户活动 ——客服二维码
*  @param params
*/
export var getStockAccounQrcode = function (params) { return new Tinker({
    url: '/api/v2/sec/getQrCode',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/*  开户活动 —— 初始化信息
*  @param params
*/
export var getStockAccounInitOpenUser = function () { return new Tinker({
    url: '/api/v2/sec/initOpenUser',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/*  开户活动 —— 登录券商账户
*  @param params
*/
export var getStockAccounIloginSec = function () { return new Tinker({
    url: '/api/v2/sec/loginSec',
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  白名单
 *  @param params
 */
export var getIsWhiteList = function (params) { return new Tinker({
    url: '/api/v2/user/abtest/tag',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 机构持仓 - 分类
 * @param params
 */
export var HttpGetInstitutionalPositionsInstLast = function (params) { return new Tinker({
    url: '/api/v3/inst/getInstLast',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 机构持仓 - 分类
 * @param params
 */
export var HttpGetInstitutionalPositionsGetTypes = function () { return new Tinker({
    url: '/api/v3/inst/getInstType',
    header: { method: 'GET' },
    dataType: 'json',
}); };
// 已上市-股价走势
export var stockTrend = function (codes) { return new Tinker({
    url: '/api/v3/ipo/monthTrend',
    header: { method: 'GET' },
    dataType: 'json',
    data: { codes: codes }
}); };
// 打新股-申购日历
export var ipoGetCalender = function () { return new Tinker({
    url: '/api/v3/ipo/ipoCalender',
    header: { method: 'GET' },
}); };
// 新股列表
export var ipoNewStockList = function (params) { return new Tinker({
    url: '/api/v3/ipo/capitalIpos',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
// 同行业-散点图
export var ipoScatter = function (params) { return new Tinker({
    url: '/api/v3/ipo/sameSw2PeMap',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 机构持仓 - 分类详情
 * @param params
 */
export var HttpGetInstitutionalPositionsGetList = function (params) { return new Tinker({
    url: '/api/v3/inst/getInstDetailByType',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 机构持仓 - 分类item详情
 * @param params
 */
export var HttpGetInstitutionalPositionsGetListItem = function (params) { return new Tinker({
    url: '/api/v3/inst/getInstDetailByInstId',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 主营构成
 *  @param params
 */
export var getincomeStructure = function (params) { return new Tinker({
    url: ' /api/v3/details/getSegmentsPeriod',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 主营构成指定项历史数据
 *  @param params
 */
export var getincomeHistory = function (params) { return new Tinker({
    url: '/api/v3/details/getSegmentsItem',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 股东历史持仓数据
 *  @param params
 */
export var getHolderDetailData = function (params) { return new Tinker({
    url: '/api/v3/details/getHolderDetailsItem',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 股票详情页-模块数据
 */
export var HttpStockModuleData = function (params) { return new Tinker({
    url: 'api/v3/data/getByconfig',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 对比指标-模块获取
 * @param params code、type:0全部；1一级行业；2二级行业；3三级行业
 */
export var getIndustryCompare = function (params) { return new Tinker({
    url: '/api/v3/details/industryCompare',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 对比指标-全屏
 * @param params code,page,property,sort
 */
export var getIndustryAllScreen = function (params) { return new Tinker({
    url: '/api/v3/details/industryCompareMore',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/*
成分股-详情页
 */
export var getConstituent = function (params) { return new Tinker({
    url: '/api/v3/details/memberList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
// 用户 是否签到 新用户 是否开户 接口合并
export var getUserDatas = function (params) { return new Tinker({
    url: '/api/v2/user/userDatas',
    data: params,
    header: { method: 'POST' },
    dataType: 'json',
}); };
//首页banner接口整合
export var getAllBanner = function (params) { return new Tinker({
    url: '/api/v2/banner/query',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
///api/v3/market/part1
export var getAllPart1 = function () { return new Tinker({
    url: '/api/v3/market/part1',
    header: { method: 'GET' },
    dataType: 'json',
    data: {}
}); };
// /api/v3/market/part2
export var getAllPart2 = function () { return new Tinker({
    url: '/api/v3/market/part2',
    header: { method: 'GET' },
    dataType: 'json',
    data: {}
}); };
// 机构预测 中盈利预测模块接口
export var getForecast = function (params) { return new Tinker({
    url: '/api/v3/details/forecast',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//5周年活动领取7天vip
export var getElevenVip = function (params) { return new Tinker({
    url: '/api/v2/user/receiveVip',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//5周年活动详情
export var getPartyInfoDetail = function (params) { return new Tinker({
    url: '/api/v2/activity/info',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//5周年活动商品列表
export var getPartyGoodsDetail = function (params) { return new Tinker({
    url: '/api/v2/goods/list_v3',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//5周年活动用户状态
export var getPartyPersonStatus = function (params) { return new Tinker({
    url: '/api/v2/raffle/userStatus',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//发放优惠券
export var getPartyCouponOffer = function (params) { return new Tinker({
    url: '/api/v2/coupon/offer',
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
//5周年填写/修改快递地址
export var setExpressInfo = function (params) { return new Tinker({
    url: '/api/v2/raffle/createExpressInfo',
    data: params,
    header: { method: 'POST' },
    dataType: 'json',
}); };
//比对新接口
export var getFund_ComparekTrendAll = function (params) { return new Tinker({
    url: '/api/v2/fund/kTrend',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//分组列表新接口
export var getOptGroupListV3 = function (params) { return new Tinker({
    url: '/api/v2/opt/optGroupListV3',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//添加自选
export var getEventReminderOptGroupList = function (params) { return new Tinker({
    url: '/api/v2/opt/eventReminderOptGroupList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//基金获取比对页面接口
export var getFundCompareV3 = function (params) { return new Tinker({
    url: '/api/v2/fund/defaultCompareCode',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// 查询入群用户是否添加了客服
export var scanAutoTags = function (params) { return new Tinker({
    url: '/api/v2/qywx/scanAutoTags',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// 20241001活动 /api/v2/tempactivity/userStatus?activityId=35
export var fetchReciveVip1001 = function (params) { return new Tinker({
    url: "/api/v2/tempactivity/userStatus",
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
/**
 *  估值与分位
 *  @param params
 */
export var getPepbData = function (params) { return new Tinker({
    url: '/api/v3/details/pepb',
    data: params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 接口基本信息
 *  @param params
 */
export var getEtfBasic = function (code) { return new Tinker({
    url: "/api/v4/etf/basic/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 接口基本信息
 *  @param params
 */
export var getEtfBasicFund = function (params) { return new Tinker({
    url: "/api/v2/fund/etfNotice",
    data: params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 接口基本信息
 *  @param params
 */
export var getEtfTrackingIndex = function (code) { return new Tinker({
    url: "/api/v4/etf/trackingIndex/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 股价强度
 *  @param params
 */
export var getRpsIndex = function (code) { return new Tinker({
    url: "/api/v4/etf/rps/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 利好利空
 *  @param params
 */
export var getEtfComment = function (code) { return new Tinker({
    url: "/api/v4/etf/comment/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 利好利空弹窗信息
 *  @param params
 */
export var getEtfCommentDetail = function (params) { return new Tinker({
    url: "/api/v3/etf/relateNews",
    data: params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 风险与收益
 *  @param params
 */
export var getReturnAndRisk = function (code) { return new Tinker({
    url: "/api/v4/etf/performance/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 持仓明细前十
 *  @param params
 */
export var getPositionTop10 = function (code) { return new Tinker({
    url: "/api/v4/etf/holdTop10/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 持仓明细全部
 *  @param params
 */
export var getPositionAll = function (code) { return new Tinker({
    url: "/api/v4/etf/holdAll/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 行业配置全部
 *  @param params
 */
export var getSectorAll = function (code) { return new Tinker({
    url: "/api/v4/etf/holdIndustry/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 相关基金
 *  @param params
 */
export var getLinkFund = function (code) { return new Tinker({
    url: "/api/v4/etf/linkFund/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 相关基金
 *  @param params
 */
export var getDicidend = function (code) { return new Tinker({
    url: "/api/v4/etf/dividend/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 持有人结构
 *  @param params
 */
export var getStructure = function (code) { return new Tinker({
    url: "/api/v4/etf/structure/" + code,
    // data:params,
    header: { method: 'GET' },
    dataType: 'json',
}); };
/**
 *  ETF详情页 是否添加自选
 *  @param params
 */
export var getConfig = function (params) { return new Tinker({
    url: "/api/v3/etf/config",
    header: { method: 'GET' },
    dataType: 'json',
    data: params
}); };
/**
 * 移动自选股到别的分组
 * @param {*} params
 */
export var moveOpts = function (params) { return new Tinker({
    url: "/api/v2/opt/moveOpt",
    data: params,
    header: { method: 'GET' }
}); };
/**
 * 移动端跳转ETF
 * @param {*} params
 */
export var getNewpage = function () { return new Tinker({
    url: "/api/v4/etf/newpage",
    header: { method: 'GET' },
    dataType: 'json',
}); };
// 首页大涨大跌原因
export var priceSwingReasons = function () { return new Tinker({
    url: "/api/v4/market/priceReason",
    header: { method: 'GET' },
    dataType: 'json',
}); };
// 首页大涨原因
export var priceSwingReasonsRise = function () { return new Tinker({
    url: "/api/v4/market/priceReasonUp",
    header: { method: 'GET' },
    dataType: 'json',
}); };
// 首页大涨原因
export var priceSwingReasonsFall = function () { return new Tinker({
    url: " /api/v4/market/priceReasonDown",
    header: { method: 'GET' },
    dataType: 'json',
}); };
//沪深港通 
export var northPcStockContent = function () { return new Tinker({
    url: " /api/v4/market/hshk",
    header: { method: 'GET' },
    dataType: 'json',
}); };
//课程卡片列表
export var courseList = function () { return new Tinker({
    url: '/api/v3/course/list',
    header: { method: 'GET' },
    dataType: 'json'
}); };
//课程列表  
export var chapterList = function (params) { return new Tinker({
    url: '/api/v3/course/chapterList',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//章节详情 
export var chapterDetail = function (params) { return new Tinker({
    url: '/api/v3/course/chapterDetail',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
// 练习题答案
export var userAnswer = function (params) { return new Tinker({
    url: '/api/v3/course/userAnswer',
    dataType: 'json',
    data: params,
    header: { method: 'POST' },
}); };
//判断是否可购买
export var hasBuy = function (params) { return new Tinker({
    url: '/api/v3/course/hasBuy',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//观看视频 
export var userWatch = function (params) { return new Tinker({
    url: '/api/v3/course/userWatch',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//获取证书 
export var getCertified = function (params) { return new Tinker({
    url: '/api/v3/course/getCertified',
    header: { method: 'GET' },
    dataType: 'json',
    data: params,
}); };
//南向活跃成交
export var getHshkDetailSouthTop = function () { return new Tinker({
    url: '/api/v4/market/hshkDetailSouthTop',
    header: { method: 'GET' },
    dataType: 'json'
}); };
//北向活跃成交
export var getHshkDetailNorthTop = function () { return new Tinker({
    url: '/api/v4/market/hshkDetailNorthTop',
    header: { method: 'GET' },
    dataType: 'json'
}); };
//南向持股情况 
export var getHshkDetailSouthValue = function () { return new Tinker({
    url: '/api/v4/market/hshkDetailSouthValue',
    header: { method: 'GET' },
    dataType: 'json'
}); };
//北向持股情况 
export var getHshkDetailNorthValue = function () { return new Tinker({
    url: '/api/v4/market/hshkDetailNorthValue',
    header: { method: 'GET' },
    dataType: 'json'
}); };
//南向持股比例
export var getHshkDetailSouthPct = function () { return new Tinker({
    url: '/api/v4/market/hshkDetailSouthPct',
    header: { method: 'GET' },
    dataType: 'json'
}); };
//北向行业分布
export var getHshkDetailNorthIndustry = function () { return new Tinker({
    url: '/api/v4/market/hshkDetailNorthIndustry',
    header: { method: 'GET' },
    dataType: 'json'
}); };
